import { Inject, Injectable, OnDestroy } from '@angular/core';
import { payrollRoutes } from '@app/payrolls/payrolls-routes';
import { Summary } from '@design/components/summary-tile/summary';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { LoggingService } from '@logging/logging.service';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { createGroupHandoverStatusPillConfig, createPayrollHandoverStatusPillConfig } from '@shared-payrolls';
import { toCurrency } from '@utils/currency-utils';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EmployerStatistics, EmployerStatisticsState } from './employer-statistics';
import { EmployerStatisticsDataProvider } from './employer-statistics.data-provider';
import { getIconConfig } from './get-icon-config';

@Injectable()
export class EmployerStatisticsStateService implements OnDestroy {
  private static storeName = 'employer-payroll-stats-models-service';

  private initialValue: EmployerStatisticsState = { employerStatistics: null };

  private readonly store = createStore(
    { name: EmployerStatisticsStateService.storeName },
    withProps<EmployerStatisticsState>(this.initialValue)
  );

  employerPayrollStats$ = this.store.pipe(
    select((q) => q.employerStatistics),
    filter((v) => !!v)
  );

  payrollSummaries$ = combineLatest([
    this.employerPayrollStats$,
    this.featureFlagService.getValue$('allowGroupHandover').pipe(map((x) => !!x))
  ]).pipe(map(([statistics, allowGroupHandover]) => this.toPayrollSummaries(statistics, allowGroupHandover)));

  constructor(
    private dataProvider: EmployerStatisticsDataProvider,
    private activeSecurity: ActiveSecurityContextStateService,
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService,
    private logger: LoggingService
  ) {}

  fetch(employerId: number) {
    this.dataProvider.readMany$(employerId).subscribe((employerStatistics) => {
      this.logger.trace('EmployerPayrollStatsStateService: data', employerStatistics);
      this.store.update(setProp('employerStatistics', employerStatistics));
    });
  }

  private toPayrollSummaries(employerPayrollStatistics: EmployerStatistics, allowGroupHandover: boolean): Summary[] {
    const summaries: Summary[] = [];
    employerPayrollStatistics.payrollSummaries.forEach((payrollSummary) => {
      const { unsubmittedGroupCount, submittedGroupCount, isLocked: isPayrollLocked } = payrollSummary;

      const nextPayDay = this.getNextPayDay(payrollSummary.daysUntilNextPayDay);

      const statusPillConfig =
        this.activeSecurity.isServiceTypeSource(payrollSummary.id) &&
        this.activeSecurity.hasOrganisationAuthorityToOneOf(['HandoverReOpenPayroll', 'HandoverSubmitPayroll'])
          ? createPayrollHandoverStatusPillConfig({
              isPayrollLocked,
              isOssUser: this.activeSecurity.isOssUser()
            })
          : null;

      const additionalStatusPillConfig =
        allowGroupHandover && (unsubmittedGroupCount > 0 || submittedGroupCount > 0)
          ? createGroupHandoverStatusPillConfig({ unsubmittedGroupCount, submittedGroupCount })
          : null;

      summaries.push({
        iconConfig: getIconConfig({ isPayrollLocked, allowGroupHandover }),
        routeTo: '/' + payrollRoutes.payrollHub(payrollSummary.id),
        label: payrollSummary.name,
        values: new Map<string, string>([
          ['Active employees', payrollSummary.employeeCount.toString()],
          ['Latest net pay', toCurrency(payrollSummary.netPay, 2, payrollSummary.currency)],
          [nextPayDay.label, nextPayDay.value]
        ]),
        employerId: payrollSummary.employerId,
        employerName: payrollSummary.employerName,
        statusPillConfig,
        additionalStatusPillConfig
      });
    });
    return summaries;
  }

  getNextPayDay = (daysTillNextPay: number): { value: string; label: string } => {
    if (daysTillNextPay === 0)
      return {
        value: 'Today',
        label: 'Next pay day'
      };

    if (daysTillNextPay < 0)
      return {
        value: `${Math.abs(daysTillNextPay)} days`,
        label: 'Since last pay day'
      };

    return {
      value: `${daysTillNextPay || '?'} days`,
      label: 'Until next pay day'
    };
  };

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
