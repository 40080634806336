import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, skipWhile } from 'rxjs/operators';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { EntityDataset } from '@entity-framework/datasets/entity-dataset';
import { LoggingService } from '@logging/logging.service';
import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { Employer } from './employer';
import { EmployersDataProvider } from './employers-data.provider';
import { EmployersState } from './employers.state';

/**
 * State for the selectors for managing a payroll where organisation (in context) has many employers has many payrolls
 */
@Injectable()
export class EmployersStateService implements OnDestroy {
  private readonly store = createStore(
    { name: 'EmployersStateService' },
    withProps<EmployersState>({ activeEmployerId: -1, employers: null })
  );

  employers$ = this.store.pipe(
    select((s) => s.employers),
    skipWhile((ds) => !ds?.length)
  );
  employersDataset$ = this.employers$.pipe(map((e: Employer[]) => new EntityDataset(e)));
  activeEmployerId$ = this.store.pipe(
    select((q) => q.activeEmployerId),
    skipWhile((e) => !e)
  );
  activeEmployer$ = this.store.pipe(
    select((q) => q.activeEmployer),
    skipWhile((e) => !e)
  );

  protected initialValue: Partial<EmployersState> = {
    editing: false
  } as any;

  constructor(
    private activeSecurity: ActiveSecurityContextStateService,
    protected dataProvider: EmployersDataProvider,
    protected route: ActivatedRoute,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService
  ) {
    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls'))
      this.dataProvider.readMany$().subscribe((employers) => this.store.update(setProp('employers', employers)));
  }

  private getCacheKey = (): string => `${this.activeSecurity.activeMembership.organisationId}_employerId`;

  /**
   * Get the last used employer id for the active organisation
   */
  getCachedEmployerId = (): number => {
    return parseInt(localStorage.getItem(this.getCacheKey()) ?? '-1', 10);
  };

  /**
   * Set the last used employer id for the active organisation
   */
  private setCachedEmployerId = (employerId: number) => {
    localStorage.setItem(this.getCacheKey(), employerId.toString());
  };

  setActiveEmployerId(activeEmployerId: number) {
    this.setCachedEmployerId(activeEmployerId);
    const activeEmployer = this.store.getValue().employers.find((e) => e.id === activeEmployerId);
    this.store.update(setProps({ activeEmployerId, activeEmployer }));
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
